import { defineComponent } from "vue";

import Hero from "@/components/hero/index.vue";
import SummitLink from "@/components/summit-link/index.vue";
import heroBg from "../../assets/images/about-us-bg.png";

export default defineComponent({
  name: "Documents",
  components: { SummitLink, Hero },
  setup() {
    const heroSlide = [
      {
        title: "Summit Report",
        image: heroBg,
      },
    ];

    return {
      heroSlide,
    };
  },
  data() {
    return {
      
      filesrcs: [
        // 04/02/2025
        { title: 'NASARAWA STATE FY 2025 BUDGET PUBLICATION', src: 'https://api.nasida.na.gov.ng/services/NASARAWA-State-FY-2025-Budget-Publication.pdf', time:'Feb 04, 2025' },
        // 31/12/2024
        { title: 'NASARAWA STATE 2024 DEBT SUSTAINABILITY ANALYSIS DEBT MANAGEMENT STRATEGY REPORT', src: 'https://api.nasida.na.gov.ng/services/NASIDA/31-12-2024/NASARAWA STATE 2024 DEBT SUSTAINABILITY ANALYSIS DEBT MANAGEMENT STRATEGY REPORT.pdf', time:'Dec 31, 2024' },
        { title: 'Data on The Export Certificates Issued in Nasarawa State', src: 'https://api.nasida.na.gov.ng/services/NASIDA/31-12-2024/Data on The Export Certificates Issued in Nasarawa State.pdf', time:'Dec 31, 2024' },
        { title: 'MINISTRY OF TRADE SERVICE LEVEL AGREEMENT', src: 'https://api.nasida.na.gov.ng/services/NASIDA/31-12-2024/MINISTRY OF TRADE SERVICE LEVEL AGREEMENT.pdf', time:'Dec 31, 2024' },
        { title: 'Report on Export certificates Issued in Nasarawa State', src: 'https://api.nasida.na.gov.ng/services/NASIDA/31-12-2024/Report on Export certificates Issued in Nasarawa State.pdf', time:'Dec 31, 2024' },
        // 24/12/2024
        { title: 'NASIDA INVESTOR TRACKING SYSTEM', src: 'https://api.nasida.na.gov.ng/services/NASIDA/NASIDA INVESTOR TRACKING SYSTEM.xlsx', time:'Dec 24, 2024' },
        { title: 'NUDB SERVICE LEVEL AGREEMENT', src: 'https://api.nasida.na.gov.ng/services/NASIDA/NUDB SERVICE LEVEL AGREEMENT.pdf', time:'Dec 24, 2024' },
        { title: 'GAZETTE HARMONIZATION REPEAL 2024', src: 'https://api.nasida.na.gov.ng/services/NASIDA/Gazette_Harmonization Repeal 2024.pdf', time:'Dec 24, 2024' },
        { title: 'NASARAWA INVESTMENT AND DEVELOPMENT AGENCY CORPORATE PLAN', src: 'https://api.nasida.na.gov.ng/services/NASIDA/NASARAWA INVESTMENT AND DEVELOPMENT AGENCY CORPORATE PLAN.pdf', time:'Dec 24, 2024' },
        { title: 'NASARAWA STATE 2025 BUSINESS ENABLING REFORMS ACTION PLAN', src: 'https://api.nasida.na.gov.ng/services/NASIDA/NASARAWA STATE 2025 BUSINESS ENABLING REFORMS ACTION PLAN.pdf', time:'Dec 24, 2024' },
         // 30/07/2024
         { title: 'NASARAWA STATE 2023 BUSINESS ENABLING REFORMS ACTION PLAN PROGRESS REPORT', src: 'https://api.nasida.na.gov.ng/downloads/NASARAWA-STATE-2023-BUSINESS-ENABLING-REFORMS-ACTION-PLAN-PROGRESS-REPORT', time:'Jul 26, 2024' },
         { title: 'NASARAWA STATE GOVERNMENT ACCOUNTANT-GENERAL’S ANNUAL REPORT WITH FINANCIAL STATEMENTS', src: 'https://api.nasida.na.gov.ng/downloads/NASARAWA-STATE-GOVERNMENT-ACCOUNTANT-GENERALS-ANNUAL-REPORT-WITH-FINANCIAL-STATEMENTS', time:'Jul 26, 2024' },
        // 29/12/2023
        { title: 'ACCESS TO CREDIT GUIDE', src: 'https://api.nasida.na.gov.ng/downloads/ACCESS-TO-CREDIT-GUIDE', time:'Dec 29, 2023' },
        { title: 'NASARAWA STATE BUSINESS ENABLING REFORM ACTION PLAN (BERAP) 2024', src: 'https://api.nasida.na.gov.ng/downloads/NASARAWA-STATE-BUSINESS-ENABLING-REFORM-ACTION-PLAN-(BERAP)-2024', time:'Dec 29, 2023' },
        { title: 'NASARAWA STATE EXPORT PROMOTION STRATEGY AND GUIDELINES', src: 'https://api.nasida.na.gov.ng/downloads/NASARAWA-STATE-EXPORT-PROMOTION-STRATEGY-AND-GUIDELINES', time:'Dec 29, 2023' },
        // 14/12/2023
        { title: 'Nasarawa State Debt Sustainability Analysis Debt Management Strategy (DSA-DMS) Report 2023', src: 'https://api.nasida.na.gov.ng/downloads/Nasarawa-State-Debt-Sustainability-Analysis-Debt-Management-Strategy-(DSA-DMS)-Report-2023', time:'Dec 13, 2023' },
        // new (31-07-2023)0
        { title: 'ANNUAL FINANCIAL REPORT FOR THE YEAR 2022', src: 'https://api.nasida.na.gov.ng/downloads/ANNUAL-FINANCIAL-REPORT-FOR-THE-YEAR-2022M', time:'Jul 31, 2023' },
        // old 
        { title: 'BUSINESS-ENABLING REFORMS ACTION PLAN (BERAP)-2023', src: 'https://api.nasida.na.gov.ng/downloads/BUSINESS-ENABLING-REFORMS-ACTION-PLAN-(BERAP)-2023', time:'Jan 29, 2021' },
        { title: 'FINANCIAL STATEMENTS - 2021', src: 'https://api.nasida.na.gov.ng/downloads/FINANCIAL-STATEMENTS - 2021', time:'Jan 29, 2021' },
        { title: 'FOURTH QUARTER (Q3) 2022 BUDGET PERFORMANCE REPORT', src: 'https://api.nasida.na.gov.ng/downloads/FOURTH-QUARTER-(Q3)-2022-BUDGET-PERFORMANCE-REPORT', time:'Jan 29, 2021' },
        { title: 'Nasarawa State Debt Sustainability Analysis DSA-DMS Report', src: 'https://api.nasida.na.gov.ng/downloads/Nasarawa-State-Debt-Sustainability-Analysis-DSA-DMS-Report', time:'Jan 29, 2021' },
        { title: 'Nasarawa State FY 2023 Approved Budget', src: 'https://api.nasida.na.gov.ng/downloads/Nasarawa-State-FY-2023-Approved-Budget', time:'Jan 29, 2021' },
        // 31/12/2023
        { title: 'AMENDED NASARAWA STATE HARMONIZATION AND ADMINISTRATION OF REVENUE LAW', src: 'https://api.nasida.na.gov.ng/downloads/AMENDED-NASARAWA-STATE-HARMONIZATION-AND-ADMINISTRATION-OF-REVENUE-LAW', time:'' },
        { title: 'NASARAWA CONSOLIDATED DEMAND NOTICE', src: 'https://api.nasida.na.gov.ng/downloads/NASARAWA-CONSOLIDATED-DEMAND-NOTICE', time:'' },
      ],
    }
  }, 

  methods: {
    goto(url) {
      window.open(url);
    },
  },


});
